<template>
  <div class="relative">
           <div class="fixed z-50 bottom-4 left-4 md:w-[250px] w-[130px] text-sm">
            <shortlist-indicator />

            </div>
    <div class="bg-mv-black">

          <video-modal ref="video" />


      <div class="w-full mx-auto  pt-5 text-white">
        <the-banner
          class="bg-mv-black hidden mt-20 z-0"
          imageUrl="MV-SuperSite-Crop.webp"
          heading="Grow Your Business with the Best"
          subheading="Purpose Built Trucks, Trailers and Vans Designed to Make Growing Your Business Easier with the UK’s Award-Winning Independent Dealer."
          :enquire-button="false"
          :lg-top-position="8"
          style="height: 800px; max-height: 900px;"
        />
      
      </div>
    </div>

      <div class="bg-mv-black lg:hidden   relative">
        <div class="">
<!-- 
      <NuxtImg
        provider="netlify"
        alt="MV Commercial Supersite at Airdrie"
        class="flex w-full  object-cover h-full" 
        src="/assets/img/banner/drone/drone-nov-23-edit-zoom-2.webp"
        style="min-height: 280px; max-height: 900px;"
        sizes="md:100vw lg:100vw"
      />  -->
          

           <TheSearch></TheSearch> 
          </div>
               <!-- <div @click="openVideoModal()" class=" text-xs absolute bottom-0 w-full bg-mv-black hover:bg-mv-red text-white text-center font-bold cursor-pointer "><i class="far fa-play mr-3"></i>Find out about who we are and what we can offer</div> -->
      
      </div>
          

    <div class="bg-white">
      <!-- Icon Search -->
      <!-- <div class="w-full hidden  md:grid grid-cols-6 max-w-4xl mx-auto gap-x-20 my-3">
        <icon-search v-for="icon in searchIcons" :key="icon" :type="icon" />
      </div> -->

      <div class="max-w-7xl md:flex w-full mx-auto">
        <!-- <main-banner-ad /> -->
        <!-- <div
          class="bg-mv-black text-white text-center md:w-9/12 mx-auto md:flex font-bold text-lg py-2 px-5 rounded-md md:justify-between align-middle"
        >
          <div class="mt-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          </div>
          <button
            class="bg-gray-200 font-semibold text-black rounded-sm md:ml-8 px-4 py-1 hover:bg-gray-300 my-5"
          >
            Learn More
          </button>
           <NuxtImg provider="netlify"
            class="w-20 mx-auto md:mx-0"
            src="/assets/img/brand/truck-banner.webp"
          />
        </div> -->
      </div>
    </div>

    <!-- Featured vehicles -->
    <div v-if="!pending" class="bg-mv-light-gray p-5">
      <div class="lg:max-w-7xl lg:flex w-full mx-auto mt-10">
        <div class="font-semibold text-2xl tracking-wide text-center w-full">
          Featured Trucks, Trailers and Vans
        </div>
      </div>

      <div class="lg:max-w-7xl lg:mx-auto w-full mt-5 lg:flex justify-center">
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-x-5 gap-y-5 md:gap-y-5"
        >
          <div
            class=" my-3 lg:my-0"
            v-for="vehicle in vehicles.mostPopular.slice(0, 4)"
            :key="vehicle.id"
          >
            <VehicleBox :vehicle="vehicle" />
          </div>
        </div>

   
        
      </div>

      <!-- <div
        class="lg:max-w-7xl lg:mx-auto w-full mt-10 flex gap-x-5 justify-center mb-10"
      >
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-x-5 gap-y-5 md:gap-y-5 h-32 overflow-hidden"
        >
          <div
            class="my-3 lg:my-0 opacity-20"
            v-for="vehicle in vehicles.mostPopular"
            :key="vehicle.id"
          >
            <VehicleBox :vehicle="vehicle" />
          </div>
        </div>

        <NuxtLink
          to="/main-search"
          class="bg-mv-black text-white hover:bg-gray-700 font-semibold font-lg px-3 py-2 rounded-sm absolute mt-10 shadow-md"
        >
          View more
        </NuxtLink>
      </div> -->
      <div class="flex justify-center mb-5">
      <NuxtLink
          to="/main-search"
          class="bg-mv-black text-white hover:bg-gray-700 font-semibold font-lg px-3 py-2 rounded-sm  mt-10 shadow-md "
        >
          View More <span class="text-green-500">Ready to Go</span> Trucks for Sale and Hire
        </NuxtLink>
        </div>
          
    </div>
    <div class="h-[1238px] mx-auto pt-20 lg:pt-52 w-full bg-mv-light-gray p-5 text-center font-bold" v-else><i class="fa-solid fa-spinner-third fa-spin mr-3"></i> Loading Featured Vehicles ...</div>

    <div v-if="!pending" class="bg-white">
      <div v-if="vehicles.justArrived.length > 0">
        <div class="max-w-7xl flex w-full mx-auto mt-10">
          <div class="font-semibold text-3xl tracking-wide text-center w-full">
            Just Arrived & Awaiting Prep
          </div>
        </div>

        <div class="lg:max-w-7xl mx-auto w-full mt-10 flex justify-center">
          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-x-5 gap-y-5 md:gap-y-0"
          >
            <div
              class="my-3 lg:my-0"
              v-for="vehicle in vehicles.justArrived"
              :key="vehicle.id"
            >
              <VehicleBox :vehicle="vehicle" />
            </div>
          </div>
        </div>

        <div
          class="max-w-7xl mx-auto w-full mt-10 flex gap-x-5 justify-center mb-10"
        >
          <NuxtLink
            to="/main-search"
            class="bg-mv-black text-white hover:bg-gray-700 font-semibold font-lg px-3 py-2 rounded-sm"
          >
            View more
          </NuxtLink>
        </div>
      </div>

      </div>
    <div class="h-[1238px] mx-auto pt-20 lg:pt-52 w-full bg-mv-light-gray p-5 text-center font-bold" v-else><i class="fa-solid fa-spinner-third fa-spin mr-3"></i> Loading Featured Vehicles ...</div>


        <div class="w-full bg-gray-200 p-2">
             <div class="max-w-5xl mx-auto my-20  md:block bg-gray-200">
          <!-- <NuxtLink class="" to="/truck-finance">
             <NuxtImg provider="netlify"
              src="/assets/img/brand/finance-banner.webp"
              class="mx-auto w-full shadow-md bg-gray-200 hover:border-b-4 hover:border-b-mv-red hover:-translate-y-1 transition-all cursor-pointer"
              loading="lazy"
              sizes="xs:100vw sm:100vw"
              alt="MV Commercial Finance Banner"

            />
          </NuxtLink> -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-5 bg-white shadow-lg p-5 rounded">
             <div class="col-span-2 prose px-5 pt-2"><h2>The UK’s Leader in Specialist Trucks, Trailers, and Vans for Sale and Hire</h2></div>

             <div class="prose mx-auto bg-white p-5">

             <p>With over <strong>400</strong> trucks for sale and almost <strong>2,000</strong> in our rental fleet, MV Commercial is proud to be one of the largest specialist truck, trailer, and van suppliers in the UK.</p>
              <p>With depots across the UK, we have one the of largest In Stock, Ready to Go fleets of specialist vehicles for sale and hire, including <a href=https://mvcommercial.com/body-type/tipper-grab>tipper grabs</a>, crane trucks and <a href=https://mvcommercial.com/body-type/skip-loader>skip loaders</a>. Both our specialist trucks for sale are all high specification, purpose built and original build products, designed to make your job easier while performing better, more reliably and with better support than any other company.</p>


              <iframe class="w-full h-96" src="https://www.youtube-nocookie.com/embed/YtJlB9ZiWZY?si=CMJ-7Byrkb4zGBsD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>


             
             
             </div>

             <div class="prose mx-auto bg-white p-5 col">
                 <p>We are already the preferred <a href=https://mvcommercial.com/blog>truck supply partner</a> to many of the UK&rsquo;s leading haulage, construction, and specialist lifting service companies, with our range of crane trucks and crane artics being widely recognized as industry leading products.</p>
              <p>In addition to this, we have a reputation for our:</p>
              <ul>
              <li>Extensive range of readily available specialised equipment</li>
              <li>Manufacturer independent approach</li>
              <li>In-house <a href=https://mvcommercial.com/truck-finance>financial solutions</a></li>
              <li>Comprehensive manufacturing capabilities</li>
              <li>Continuous 24/7 customer service and assistance</li>
              </ul>
              <p>Our manufacturer independence means we are not tied to any one manufacturer, and instead can offer the right purpose-built unit to meet your business&rsquo; unique needs. Whether you are looking for a Scania, DAF, Volvo, or other chassis, paired with a Hiab, Palfinger or Cormach crane, we always supply the best combination of chassis, body and cranes for sale and hire in the UK.</p>
              <p>If you are looking for the perfect trucks for sale for your business, speak to us today to find we can put our <a href=https://mvcommercial.com/blog/post/pr-mv-commercial-recognised-in-febe-growth-100-line-up>award-winning </a>independence and expertise at your service.</p>
              <p>
              </p>
             </div>
             </div>

           

        </div>

    

        </div>

      <!-- CTA section -->
      <div class="max-w-7xl mx-auto mb-5 mt-20 hidden md:block">

           <NuxtLink class="mb-10" to="/truck-finance">
      
            <carousel :autoplay="3000" :wrap-around="true" ref="imageCarousel" :items-to-show="1">
                  <slide v-for="image in financeBanners" :key="image">
                    <NuxtImg
                    provider="netlify"
                    class="w-full rounded-t-md object-cover h-full"
                    :src="image.path"
                    loading="lazy"
                    :alt="image.alt"
                  />
                  </slide>

                </carousel>
              </NuxtLink>
        <div class="font-semibold text-2xl w-full text-center">
          The MV Commercial Difference
        </div>
      </div>

      <div
        class="max-w-7xl md:mx-auto md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-5 gap-x-5 mx-5 mb-20 hidden"
      >
        <nuxt-link
          :href="cta.link"
          :key="cta.title"
          class="border border-gray-400 hover:shadow-xl hover:border-b-4 hover:border-b-mv-red hover:-translate-y-1 transition-all cursor-pointer"
          v-for="cta in ctas"
        >
           <NuxtImg  :alt="'Image for'+cta.title" provider="" :src="cta.image" loading="lazy"  sizes='xs:300px sm:500px md:700px lg:1200px xl:1920px'
            class="w-full"/>
          <div class="p-3 text-base">
            <div class="font-bold mb-5">{{ cta.title }}</div>
            <div class="text-mv-gray leading-6 text-base tracking-wide">
              {{ cta.description }}
            </div>
          </div>
        </nuxt-link>
      </div>


      <div class="hidden md:block" v-if="!postsPending && posts.topFifteenPosts.length > 0">
      
        <div class="max-w-7xl mx-auto mt-20">
          <h2 class="font-semibold text-2xl w-full my-5 text-center">
            What's new
          </h2>
        </div>


        <div
          class="max-w-7xl md:mx-auto mx-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-y-5 gap-x-5">
          <NuxtLink
            
            :to="`/blog/post/${post.slug}`"
            class="hover:shadow-xl hover:border-b-4 hover:border-b-mv-red hover:-translate-y-1 transition-all cursor-pointer"
            v-for="(post) in posts.topFifteenPosts.slice(0,3)"
          >
            <NuxtImg
              provider="imagekit"
              class="w-full object-cover rounded-t-md h-full max-h-72"
              :src="'mvc/'+post.image"
              sizes='xs:300px sm:500px md:700px lg:1200px xl:1920px'
              :alt="'Image for '+post.title"
              loading="lazy"
            />
            <div
              class="font-bold text-base tracking-wide w-full mt-5 line-clamp-2"
            >
              {{ post.title }}
            </div>
          </NuxtLink>

         <!-- <NuxtLink
            :to="`/blog/post/${posts.topFifteenPosts[1].slug}`"
            class="hover:shadow-xl hover:border-b-4 hover:border-b-mv-red hover:-translate-y-1 transition-all cursor-pointer"
          >
            <NuxtImg
              provider="imagekit"
              class="w-full object-cover rounded-t-md h-full max-h-72"
              :src="
                'mvc/uploads/post/image/' +
                posts.topFifteenPosts[1].id +
                '/' +
                posts.topFifteenPosts[1].image
              "
              loading="lazy"
              :alt="'Image for '+posts.topFifteenPosts[1].title"
              
              sizes="xs:100vw sm:100vw"
            />
            <div
              class="font-bold text-base tracking-wide w-full mt-5 line-clamp-2"
            >
              {{ posts.topFifteenPosts[1].title }}
            </div>
         </NuxtLink>

          <NuxtLink
            :to="`/blog/post/${posts.topFifteenPosts[2].slug}`"
            class="hover:shadow-xl hover:border-b-4 hover:border-b-mv-red hover:-translate-y-1 transition-all cursor-pointer"
          >

            <NuxtImg
              provider="imagekit"
              class="w-full object-cover rounded-t-md h-full max-h-72"
              :src="
                'mvc/uploads/post/image/' +
                posts.topFifteenPosts[2].id +
                '/' +
                posts.topFifteenPosts[2].image
              "
              loading="lazy"
              :alt="'Image for '+posts.topFifteenPosts[2].title"
              sizes="xs:100vw sm:100vw"
            />
            <div
              class="font-bold text-base tracking-wide w-full mt-5 line-clamp-2"
            >
              {{ posts.topFifteenPosts[2].title }}
            </div>
          </NuxtLink> -->
        </div>

        <div class="w-full text-center mt-10 mb-20">
          <NuxtLink to="/blog">
            <div
              class="border-mv-black border font-semibold text-sm px-3 py-2 w-28 mx-auto cursor-pointer hover:bg-black hover:text-white"
            >
              View more
            </div>
          </NuxtLink>
        </div>

 
      </div>




    </div>
</template>



<script setup>
import { useFiltersStore } from "~/store/filters";
import { useWebsiteModeStore } from "~/store/websiteMode";
import { storeToRefs } from "pinia";

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

useSeoMeta({
  title: 'MV Commercial | New and Used Trucks for Sale in the UK',
  ogTitle: 'MV Commercial | New and Used Trucks for Sale in the UK',
  description: 'MV Commercial is a leading supplier of new and used trucks for sale in the UK. We also specialise in truck rental, crane truck rental and tipper and grab hire.',
  ogTitle: 'MV Commercial | New and Used Trucks for Sale in the UK',
  ogDescription: 'MV Commercial is the UK’s largest supplier of new and used commercial vehicles. With a huge range of new and used vehicles on display including Crane Trucks, Tippers and Grabs, you’ll find the right vehicle for your business at MV Commercial.',
  ogImage: '/assets/img/brand/logo.webp',
  ogUrl: 'https://www.mvcommercial.com/',
  twitterTitle: 'MV Commercial | New & Used Commercial Vehicles',
  twitterDescription: 'MV Commercial is the UK’s largest supplier of new and used commercial vehicles. With a huge range of new and used vehicles on display, you’ll find the right vehicle for your business at MV Commercial.',
  twitterImage: '/assets/img/brand/logo.webp',
  twitterCard: 'summary_large_image',
})

const config = useRuntimeConfig();
const filtersList = { "front-page": true };

const filtersStore = useFiltersStore();
const { clearFilters } = filtersStore;
const { addValueToFilterList, removeFilter, getFilterList } = filtersStore;

const websiteModeStore = useWebsiteModeStore();


const { mode } = storeToRefs(websiteModeStore);
const {setMode} = websiteModeStore;

const video = ref();

const url = useRequestURL()

const currentUrl = url.href     // https://example.com:3000/hello-world    
const protocol = url.protocol   // https:
const host = url.host           // example.com:3000
const hostname = url.hostname   // example.com
const pathname = url.pathname   // /hello-world

const financeBanners = [
  {
    path: "assets/img/brand/finance-banners/2024 - Finance - Banners1.webp",
    alt: "MV Commercial Finance Banner",
  },
  {
    path: "assets/img/brand/finance-banners/2024 - Finance - Banners2.webp",
    alt: "MV Commercial Finance Banner",
  },
  {
    path: "assets/img/brand/finance-banners/2024 - Finance - Banners3.webp",
    alt: "MV Commercial Finance Banner",
  },
];


onMounted(() => {
  clearFilters();

  if(host.includes("rental")) {
    setMode('rental');
  }

// useBugsnag().notify('Test error', 'Test error message')


});

const {

  data: posts,
  error: postsError,
  pending: postsPending,
  execute: postsExecute,
  refresh: postsRefresh,
} = await useFetch(
  config.public["VEHICLE_API_BASE_URL"] + "/website-api/get-blog-posts",
  {
    server: true,
    lazy: true,
  }
);

const {
  data: vehicles,
  error,
  pending,
  execute,
  refresh,
} = await useFetch(
  config.public["VEHICLE_API_BASE_URL"] + "/website-api/get-vehicles",
  {
    params: { filtersList },
    server: true,
    lazy: true,
  }
);

const ctas = [
  {
    title: "24/7 Support",
    image: "/assets/img/brand/cta/24-7-support.webp",
    description:
      "Drive your success, day and night! Experience uninterrupted operations with our 24/7 support, reducing delays, and maximizing your fleet uptime.",
    link: "/",
  },

  {
    title: "In House Finance",
    image: "/assets/img/brand/cta/finance.webp",
    description:
      "Your fleet's financial partner in every mile! Our in-house finance experts deliver personalized attention, unique industry perspective, and faster approvals for your fleet growth.",
    link: "/truck-finance",
  },

  {
    title: "Ready to go Fleet",
    image: "/assets/img/brand/cta/ready-to-go-fleet.webp",
    description:
      "Your shortcut to growth! Our Ready to Go Fleet offers hassle-free turnkey solutions, reduced on the road times, and improved fleet flexibility so you can secure new contracts quicker.",
    link: "/main-search",
  },

  {
    title: "Our Fabrication",
    image: "/assets/img/brand/cta/our-fabrication.webp",
    description:
      "Your fleet, your vision! Our fabricators are experts at building purpose-built bespoke truck solutions, ensuring your trucks are custom built to meet your exact requirements.",
    link: "/our-services/fabrication",
  },
];

const searchIcons = [
  "box_and_curtain",
  "crane_truck",
  "dropside_and_flatbed",
  "skip_and_hookloader",
  "tipper_and_grab",
  "tractor_unit",
];


function openVideoModal() {
   video.value.openModal();
}
</script>